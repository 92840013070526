import { useQuery } from '@tanstack/react-query';

import { isNotNullOrUndefined, isNullOrUndefined } from '../../../utils/utils';
import { CLMComponentsConfig } from '../types';
import { axiosInstance } from '../../../shared/axios/axiosConfig';

export const useCLMComponentsConfig = () => {
  const localStorageData = localStorage.getItem('clm-components-config');
  let validCachedData: CLMComponentsConfig | null = null;

  if (isNotNullOrUndefined(localStorageData)) {
    const parsedData = JSON.parse(localStorageData) as {
      config: CLMComponentsConfig;
      validUntil: Date;
    };

    if (new Date() < new Date(parsedData.validUntil)) {
      validCachedData = parsedData.config;
    }
  }

  const { data, isLoading } = useQuery<CLMComponentsConfig>({
    queryKey: ['clm-components-config'],
    queryFn: async () => {
      const res = await axiosInstance.get('/clm-components-config');
      return res.data;
    },
    enabled: isNullOrUndefined(validCachedData),
  });

  if (isNullOrUndefined(validCachedData)) {
    localStorage.setItem(
      'clm-components-config',
      JSON.stringify({
        config: data,
        validUntil: new Date(new Date().setDate(new Date().getDate() + 1)),
      }),
    );
  }

  return {
    data: validCachedData ?? data,
    isLoading,
  };
};
