import api from './Api';

const UserService = {
  async getUsers(roles?: string[], dataScopeType?: string, ouDataScope?: string) {
    const response = await api.get('users', {
      params: {
        roles,
        dataScopeType,
        ouDataScope,
      },
    });
    return response.data;
  },
};
export default UserService;
