import React from 'react';

import { Chip, Divider, ListItem, Typography } from '@mui/material';

export const Footnotes = () => {
  return (
    <>
      <Divider style={{ width: '100%', paddingTop: '20px' }}>
        <Chip label="Notes" size="small" />
      </Divider>
      <Typography variant="body2">
        <ListItem style={{ display: 'list-item' }}>
          Application must be used from a private window and please allow all the pop-ups from the url
        </ListItem>
        <ListItem style={{ display: 'list-item' }}>
          If you have already logged in with a user and would like to login with a different user, please close all
          private browser windows first.
        </ListItem>
      </Typography>
    </>
  );
};
