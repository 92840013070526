import api from './Api';

const LinkService = {
  renderLinks: async (userId: string, password: string): Promise<string[]> => {
    const response = await api.post(`/render-links`, {
      userId,
      password,
    });
    return response.data;
  },
};
export default LinkService;
