import { QueryObserverResult, useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../shared/axios/axiosConfig';
import { InfoDto } from './types';

export const useComponentInfo = (component: string | undefined): QueryObserverResult<InfoDto> => {
  return useQuery<InfoDto, any>({
    queryKey: ['info', component],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`/backend/${component}/actuator/info`);
      return data;
    },
  });
};
