import { AxiosRequestConfig } from 'axios';

import { axiosInstance } from '../shared/axios/axiosConfig';

const ApiService = {
  async get(url: string, config?: AxiosRequestConfig) {
    try {
      return await axiosInstance.get(url, config);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async post(url: string, data: unknown) {
    try {
      return await axiosInstance.post(url, data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default ApiService;
