import React, { useEffect, useState } from 'react';

import { Box, Button, Container, Grid, Stack } from '@mui/material';
import { Formik } from 'formik';

import LinkService from '../../services/LinkService';
import { AvailableData } from '../AvailableData/AvailableData';
import { initialValues } from '../types/LoginType';
import { LinkError } from '../../errorHandling/LinkError';
import { useUserData } from '../../hooks/useUsers';
import { isNotNullOrUndefined, isNullOrUndefined } from '../../utils/utils';
import { convertUserToAvailableFilters, filterUsers } from '../AvailableData/AvailableDataFilter';
import AutocompleteComponent from '../Autocomplete/EnhancedAutocomplete';

const useLoginData = () => {
  const userData = useUserData();
  return convertUserToAvailableFilters(userData.users);
};

const Login: React.FC = () => {
  const allAvailableUsers = useLoginData();
  const autocompleteStyles = { minWidth: 200, maxWidth: 300, width: '100%' };
  const [availableUsers, setAvailableUsers] = useState<AvailableData>(allAvailableUsers);
  const [selectedValues, setSelectedValues] = useState(initialValues);

  useEffect(() => {
    const filtered = filterUsers(selectedValues, allAvailableUsers);

    setAvailableUsers((prevAvailableUsers) => {
      if (isNullOrUndefined(prevAvailableUsers) || filtered.length !== prevAvailableUsers.users.length) {
        return convertUserToAvailableFilters(filtered);
      }
      return prevAvailableUsers;
    });
  }, [selectedValues, allAvailableUsers]);

  const handleInputChange = (field: keyof typeof selectedValues, value: string) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const onSubmit = async () => {
    try {
      const selectedUser = allAvailableUsers.users.find((user) => user.userId === selectedValues.userId);
      if (isNotNullOrUndefined(selectedUser)) {
        const links = await LinkService.renderLinks(selectedValues.userId, selectedUser.password);
        for (const link of links) {
          window.open(link, '_blank');
        }
      }
    } catch (error) {
      throw new LinkError(`Links not found - ${error}`);
    }
  };

  return (
    <Container maxWidth={'xl'}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={null}>
        {() => (
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Box
              component={'form'}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 1,
                m: 1,
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <Grid item>
                <Stack direction="row" spacing={2}>
                  <AutocompleteComponent
                    label="Roles"
                    options={availableUsers.roles}
                    inputValue={selectedValues.role}
                    onInputChange={(event, newInputValue) => handleInputChange('role', newInputValue)}
                    id="roles"
                    sx={autocompleteStyles}
                    dataTestId="role-dropdown"
                  />
                  <AutocompleteComponent
                    label="Data Scope Types"
                    options={availableUsers.dataScopeTypes}
                    inputValue={selectedValues.dataScopeType}
                    onInputChange={(event, newInputValue) => handleInputChange('dataScopeType', newInputValue)}
                    id="dataScopes"
                    sx={autocompleteStyles}
                    dataTestId="data-scope-dropdown"
                  />
                  <AutocompleteComponent
                    label="Ou Data Scopes"
                    options={availableUsers.ouDataScopes}
                    inputValue={selectedValues.ouDataScope}
                    onInputChange={(event, newInputValue) => handleInputChange('ouDataScope', newInputValue)}
                    id="ouDataScope"
                    sx={autocompleteStyles}
                    dataTestId="ou-data-scope-dropdown"
                  />
                  <AutocompleteComponent
                    label="Users"
                    options={availableUsers.users}
                    inputValue={selectedValues.userId}
                    onInputChange={(event, newInputValue) => handleInputChange('userId', newInputValue)}
                    id="userId"
                    getOptionLabel={(user) => user.userId}
                    sx={autocompleteStyles}
                    dataTestId="user-id-dropdown"
                  />
                  <Button variant="text" onClick={() => onSubmit()} data-testid="login-button">
                    Login
                  </Button>
                </Stack>
              </Grid>
            </Box>
          </Grid>
        )}
      </Formik>
    </Container>
  );
};

export default Login;
