export interface LoginTypeDto {
  userId: string;
  password: string;
  roles: string[];
  dataScopeType: string;
  ouDataScopes: string[];
}

export interface LoginType {
  userId: string;
  password: string;
  role: string;
  dataScopeType: string;
  ouDataScope: string;
}

export const initialValues: LoginType = {
  userId: '',
  password: '',
  role: '',
  dataScopeType: '',
  ouDataScope: '',
};
