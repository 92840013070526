import { isNull, isUndefined } from 'lodash';

export const isNullOrUndefined = (value: unknown): value is null | undefined => isNull(value) || isUndefined(value);

export const isNotNullOrUndefined = <T>(value: T | undefined | null): value is T => !isNullOrUndefined(value);

export const isStringNotBlank = (value?: string | undefined | null): value is string =>
  isNotNullOrUndefined(value) && value.trim().length > 0;

export const isStringBlank = (value?: string | undefined | null): value is undefined => !isStringNotBlank(value);

export const uniqByFilter = <T>(array: T[]) => array.filter((value, index) => array.indexOf(value) === index);
