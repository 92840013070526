import React from 'react';
import { useParams } from 'react-router-dom';

import { useComponentHealth } from '../hooks/useComponentHealth';
import { useComponentInfo } from '../hooks/useComponentInfo';

function Drilldown() {
  const { subcomponent } = useParams();

  const { data: componentHealth } = useComponentHealth(subcomponent);

  const { data: componentInfo } = useComponentInfo(subcomponent);

  return (
    <>
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <div>
          <div>Health Details:</div>
          <div>Health: {componentHealth?.status}</div>
          <div>DB: {componentHealth?.components?.db.status}</div>
          <div>JMS: {componentHealth?.jms?.status}</div>
          <div>{componentHealth?.livenessState?.status}</div>
          <div>{componentHealth?.readinessState?.status}</div>
        </div>
        <div>
          <div>
            <div>Build Information:</div>
            <div>Version: {componentInfo?.build?.version}</div>
            <div>Build time: {componentInfo?.build?.time}</div>
          </div>
          <div>
            <div>Git URL: {componentInfo?.git?.remote?.origin.url}</div>
            <div>Last commit message :{componentInfo?.git?.commit.message?.full}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Drilldown;
