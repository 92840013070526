import { LoginType, LoginTypeDto } from '../types/LoginType';
import { isNotNullOrUndefined, isStringBlank, uniqByFilter } from '../../utils/utils';
import { AvailableData } from './AvailableData';

export const convertUserToAvailableFilters = (userData: LoginTypeDto[]): AvailableData => {
  const roleData = uniqByFilter(userData.flatMap((u) => u.roles).filter((r) => isNotNullOrUndefined(r)));
  const scopeData = uniqByFilter(userData.map((u) => u.dataScopeType).filter((r) => isNotNullOrUndefined(r)));
  const ouScopeData = uniqByFilter(userData.flatMap((u) => u.ouDataScopes).filter((r) => isNotNullOrUndefined(r)));

  return { users: userData, roles: roleData, dataScopeTypes: scopeData, ouDataScopes: ouScopeData };
};

export const filterUsers = (selectedValues: LoginType, allAvailableUsers: AvailableData) => {
  return allAvailableUsers.users.filter(
    (user) =>
      (isStringBlank(selectedValues.userId) ||
        user.userId?.toLocaleLowerCase().includes(selectedValues.userId.toLocaleLowerCase())) &&
      (isStringBlank(selectedValues.role.toLocaleLowerCase()) ||
        user.roles.some((role) => role.toLocaleLowerCase().includes(selectedValues.role.toLocaleLowerCase()))) &&
      (isStringBlank(selectedValues.dataScopeType) ||
        user.dataScopeType?.toLocaleLowerCase().includes(selectedValues.dataScopeType.toLocaleLowerCase())) &&
      (isStringBlank(selectedValues.ouDataScope) ||
        user.ouDataScopes?.some((scope) =>
          scope.toLocaleLowerCase().includes(selectedValues.ouDataScope.toLocaleLowerCase()),
        )),
  );
};
