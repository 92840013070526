import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface EnhancedAutocompleteProps<T> {
  label: string;
  inputValue: string;
  onInputChange: (id: string, newInputValue: string) => void;
  id: string;
  options: T[];
  getOptionLabel?: (option: T) => string;
  sx?: React.CSSProperties;
  dataTestId: string;
}
function EnhancedAutocomplete<T>({
  label,
  inputValue,
  onInputChange,
  id,
  options,
  getOptionLabel = (option) => option as any,
  sx,
  dataTestId,
}: EnhancedAutocompleteProps<T>) {
  return (
    <Autocomplete
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => onInputChange(id, newInputValue)}
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      sx={sx}
      renderInput={(params) => <TextField {...params} label={label} />}
      data-testid={dataTestId}
    />
  );
}

export default EnhancedAutocomplete;
