import { QueryObserverResult, useQuery } from '@tanstack/react-query';

import { axiosInstance } from '../../../shared/axios/axiosConfig';
import { HealthDto } from './types';

export const useComponentHealth = (component: string | undefined): QueryObserverResult<HealthDto> => {
  return useQuery<HealthDto, any>({
    queryKey: ['health', component],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`/backend/${component}/actuator/health`);
      return data;
    },
  });
};
