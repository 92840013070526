import { useEffect, useState } from 'react';

import { addDays, addMinutes } from 'date-fns';
import { AxiosError } from 'axios';

import { isNotNullOrUndefined, isStringNotBlank } from '../utils/utils';
import UserService from '../services/UserService';
import { LoginTypeDto } from '../components/types/LoginType';

export const LOCALSTORAGE_PREFIX = 'adminPortal/';
export const USERS_LOCAL_STORAGE_KEY = LOCALSTORAGE_PREFIX + 'users';

type UsersLocalstorageData = {
  validUntil: number;
  users: LoginTypeDto[];
};

export const useUserData = () => {
  const [userData, setUserData] = useState<LoginTypeDto[]>([]);
  const [error, setError] = useState<AxiosError | undefined>(undefined);

  useEffect(() => {
    const storedUserData = getUserDataFromLocalStorage();
    if (isStorageDataValid(storedUserData)) {
      setUserData(storedUserData.users);
    } else {
      UserService.getUsers()
        .then((userState) => {
          putUserDataToLocalStorage(userState);
          setUserData(userState);
        })
        .catch((error) => {
          clearUserDataFromLocalStorage();
          putUserDataToLocalStorage([], addMinutes(new Date(), 1).getTime());
          setUserData([]);
          setError(error);
        });
    }
  }, []);

  return {
    users: userData,
    error,
  };
};

export const putUserDataToLocalStorage = (users: LoginTypeDto[], validUntil?: number) => {
  const definitiveValidUntil = isNotNullOrUndefined(validUntil) ? validUntil : addDays(new Date(), 1).getTime();
  const storageData: UsersLocalstorageData = { users, validUntil: definitiveValidUntil };
  localStorage.setItem(USERS_LOCAL_STORAGE_KEY, JSON.stringify(storageData));
};

export const getUserDataFromLocalStorage = (): UsersLocalstorageData | undefined => {
  const valueFromLocalStorage = localStorage.getItem(USERS_LOCAL_STORAGE_KEY);
  if (isStringNotBlank(valueFromLocalStorage)) {
    try {
      return JSON.parse(valueFromLocalStorage);
    } catch {
      // eslint-disable-next-line no-console
      console.error(`Parsing of ${USERS_LOCAL_STORAGE_KEY} from local storage failed`);
    }
  }
  return undefined;
};

export const clearUserDataFromLocalStorage = () => localStorage.removeItem(USERS_LOCAL_STORAGE_KEY);

const isNowBefore = (validUntil?: number) => {
  const currentTime: number = Date.now();
  return isNotNullOrUndefined(validUntil) && validUntil > currentTime;
};

const isStorageDataValid = (
  storedUserData: UsersLocalstorageData | undefined,
): storedUserData is UsersLocalstorageData =>
  isNotNullOrUndefined(storedUserData) &&
  isNotNullOrUndefined(storedUserData.users) &&
  storedUserData.users.length > 0 &&
  isNowBefore(storedUserData.validUntil);
