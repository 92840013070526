import './App.css';

import React, { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { Box, Button, Drawer } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

function App() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Box
          sx={{
            position: 'absolute',
            top: '1rem',
            left: '1rem',
          }}
        >
          <Button onClick={() => toggleDrawer(true)}>
            <Menu />
          </Button>
        </Box>
        <Drawer open={open} onClose={() => toggleDrawer(false)}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '1rem',
              gap: '.5rem',
            }}
          >
            <Link to="/">CLM Login</Link>
            <Link to="/info">CLM Components Status</Link>
          </Box>
        </Drawer>
        <Outlet />
      </div>
    </QueryClientProvider>
  );
}

export default App;
