import React, { useEffect } from 'react';

import { useCLMComponentsConfig } from '../hooks/useCLMComponentsConfig';
import { useComponentHealth } from '../../info-drilldown/hooks/useComponentHealth';
import { useComponentInfo } from '../../info-drilldown/hooks/useComponentInfo';

function InfoOverviewPage() {
  const query = useCLMComponentsConfig();
  const _healthTest = useComponentHealth('pcm-br-purpose-mgmt');
  const _infoTest = useComponentInfo('pcm-br-purpose-mgmt');

  useEffect(() => {
    document.title = 'CLM Components Status';
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div>
        Under Construction
        <div>
          <pre>{JSON.stringify(query.data, null, 2)}</pre>
        </div>
      </div>
    </div>
  );
}

export default InfoOverviewPage;
